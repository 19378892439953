import clsx from "clsx";
import { useState, type ReactNode, useEffect } from "react";
import { useRouter } from "next/router";

import AnimateHeight from "react-animate-height";

import slugify from "slugify";

import { FaPlus } from "react-icons/fa";

type AccordionProps =
  | {
      title: string;
      children?: ReactNode;
      open: boolean;
      onChange: (newState: boolean) => void;
    }
  | {
      title: string;
      children?: ReactNode;
      open?: undefined;
      onChange?: undefined;
    };

const Accordion = ({ title, children, open, onChange }: AccordionProps) => {
  const hash =
    typeof window !== "undefined"
      ? window.location.hash.split("#")[1]
      : undefined;

  const [hashToAddToUrl, addHashToUrl] = useState<string | null>(null);

  // Generate slug from title
  const slug = slugify(title, {
    lower: true, // convert to lower case
    strict: true, // strip special characters except replacement
  });

  const accordionOpenUseState = useState<boolean>(hash === slug);

  // On first render, if the user's URL hash equals this accordion's slug, open it up
  useEffect(() => {
    if (hash === slug) {
      onChange(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const router = useRouter();

  // Wait for a hash that needs to be added to the url, add it and then clear state
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (hashToAddToUrl) {
        // Update the URL hash without scrolling

        // Update window.history directly without scrolling
        // Note: This is a workaround and introduces a bug where navigating back to this page is broken
        //       but this is preferable to scrolling to the top of the page every time the hash changes
        // TODO: Replace with next/router once scroll: false is supported
        // See: https://github.com/vercel/next.js/discussions/13804#discussioncomment-7711088
        const url = new URL(window.location.href);
        url.hash = hashToAddToUrl;
        window.history.replaceState(null, "", url.toString());

        addHashToUrl(null);
      }
    }
  }, [hashToAddToUrl, router]);

  const [accordionOpen, setAccordionOpen] =
    open !== undefined
      ? [open, (newState) => onChange(newState)]
      : accordionOpenUseState;

  function toggleMoreInfo(event) {
    if (accordionOpen === true) {
      setAccordionOpen(false);
      router.replace(
        {
          hash: undefined,
        },
        undefined,
        { scroll: false },
      );
    } else {
      setAccordionOpen(true);
      addHashToUrl(slug);
    }
  }

  return (
    <div className="py-2">
      {/* Summary */}
      <div
        className="flex cursor-pointer items-center justify-between gap-4 font-bold text-blue"
        onClick={toggleMoreInfo}
      >
        {/* Don't render if it's about to be added to the URL to prevent scrolling */}
        <div id={hashToAddToUrl === null ? slug : undefined}>{title}</div>
        <div>
          <FaPlus
            className={clsx("transition-transform", {
              "rotate-45": accordionOpen,
            })}
          />
        </div>
      </div>
      {/* Details */}
      <AnimateHeight duration={500} height={accordionOpen ? "auto" : 0}>
        <div className="mb-2 mt-4 text-gray-dark">{children}</div>
      </AnimateHeight>
    </div>
  );
};

export default Accordion;
