import React from "react";
import AccordionGroup from "~/components/ui/AccordionGroup";

import type { InferRenderersForComponentBlocks } from "@keystone-6/fields-document/component-blocks";
import type { componentBlocks } from "@edwt/cms/schema/component-blocks";

type AccordionInputProps = InferRenderersForComponentBlocks<
  typeof componentBlocks
>["accordion"];

export const Accordion: AccordionInputProps = ({ items }) => {
  return (
    <div className="">
      <AccordionGroup
        items={items.map((item) => {
          return {
            title: item.title,
            details: (
              <p className="m-0 whitespace-pre-line p-0">{item.content}</p>
            ),
          };
        })}
      />
    </div>
  );
};
