import type { InferRenderersForComponentBlocks } from "@keystone-6/fields-document/component-blocks";
import type { componentBlocks } from "@edwt/cms/schema/component-blocks";
import { Icon } from "../Icon/Icon";

type InfoColumnsInputProps = InferRenderersForComponentBlocks<
  typeof componentBlocks
>["infoColumns"];

export const InfoColumns: InfoColumnsInputProps = ({
  footer,
  items,
  title,
}) => {
  return (
    <section className="not-prose rounded-3xl bg-blue-lightest px-6 py-5 leading-6 sm:px-10 sm:py-7 ">
      <h2 className="mb-10 text-center text-xl">{title}</h2>
      <ol className="mb-10 flex flex-wrap justify-center gap-7 p-0">
        {items.map((column, index) => (
          <li
            className="min-w-[140px] max-w-[180px] flex-1"
            key={`col-${index}`}
          >
            <div className="flex justify-center text-blue">
              {column.icon && (
                <Icon
                  name={column.icon}
                  className="mb-5 aspect-square max-w-10"
                  size="100%"
                />
              )}
            </div>
            <h3 className="text-md mb-5  text-center font-bold text-blue">
              {column.title}
            </h3>
            <div className="break-word text-center text-sm">
              {column.content}
            </div>
          </li>
        ))}
      </ol>
      <div className="flex justify-center border-t border-blue-lighter pt-5">
        <div className="max-w-xs text-center text-sm">{footer}</div>
      </div>
    </section>
  );
};
