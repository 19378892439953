import React, { ComponentProps } from "react";
import { DocumentRenderer as KeystoneDocumentRenderer } from "@keystone-6/document-renderer";
import { InferRenderersForComponentBlocks } from "@keystone-6/fields-document/component-blocks";
import { componentBlocks } from "@edwt/cms/schema/component-blocks";

// import { Callout } from "./Callout/Callout";
import { Accordion } from "./Accordion/Accordion";
import { Resources } from "./Resources/Resources";
import { InfoColumns } from "./InfoColumns/InfoColumns";
import clsx from "clsx";

type DocumentRendererProps = ComponentProps<typeof KeystoneDocumentRenderer>;

const defaultElementRenderers: DocumentRendererProps["renderers"] = {
  block: {
    // all custom components are block components
    // so they will be wrapped with a <div /> by default
    // we can override that to whatever wrapper we want
    // for eg. using React.Fragment wraps the component with nothing
    // block: React.Fragment,
    // customise blockquote elements with your own styles
    blockquote({ children }) {
      return <blockquote className="">{children}</blockquote>;
    },
    // block code ``` ```
    code({ children }) {
      return <pre className="">{children}</pre>;
    },
    paragraph({ textAlign, ...props }) {
      return (
        <p
          className={clsx("min-h-1", {
            "text-center": textAlign === "center",
            "text-end": textAlign === "end",
          })}
          {...props}
        ></p>
      );
    },
    // and more - check out the types to see all available block elements
  },
  inline: {
    bold: ({ children }) => {
      return <strong style={{ color: "#363945" }}>{children}</strong>;
    },
    // inline code ` `
    code: ({ children }) => {
      return <code className="">{children}</code>;
    },
    // and more - check out the types to see all available inline elements
  },
};

const customComponentRenderers: InferRenderersForComponentBlocks<
  typeof componentBlocks
> = {
  accordion: (props) => <Accordion {...props} />,
  resources: (props) => <Resources {...props} />,
  infoColumns: (props) => <InfoColumns {...props} />,
};

export function DocumentRenderer({ document }: DocumentRendererProps) {
  return (
    <KeystoneDocumentRenderer
      renderers={defaultElementRenderers}
      componentBlocks={customComponentRenderers}
      document={document}
    />
  );
}
