import React, { ComponentProps } from "react";
import ResourcesComponent from "~/components/ui/Resources";

import type { InferRenderersForComponentBlocks } from "@keystone-6/fields-document/component-blocks";
import type { componentBlocks } from "@edwt/cms/schema/component-blocks";

type ResourcesInputProps = InferRenderersForComponentBlocks<
  typeof componentBlocks
>["resources"];

export const Resources: ResourcesInputProps = ({
  items = [],
}: ComponentProps<typeof ResourcesComponent>) => {
  return (
    <div className="">
      <ResourcesComponent items={items} />
    </div>
  );
};
