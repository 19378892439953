import type { NextPage } from "next";
import Image from "next/image";

import Layout from "~/components/layout/DefaultLayout";

import { m, LazyMotion } from "framer-motion";
import AddressSearch from "~/components/ui/AddressSearch";
import { DocumentRenderer } from "~/components/ui/DocumentRenderer";

// Keystone
import { keystoneContext } from "~/lib/keystone";

import { getSponsors } from "@edwt/cms/prisma";
import type { Sponsor, WelcomePage } from ".prisma/client";

import { env } from "~/env";

// lazyload framer motion features
const lazyloadFeatures = () =>
  import("~/helpers/framerMotionLazyloadFeatures").then((res) => res.default);

/**
 * https://nextjs.org/docs/pages/building-your-application/data-fetching/get-static-props
 */
export async function getStaticProps({ req, res }) {
  if (process.env.BUILDING)
    return {
      notFound: true,
    };

  console.log("⚙️ Generating static page for Welcome route ('/welcome')");

  const sponsors = await getSponsors({
    prisma: keystoneContext.prisma,
  });

  const welcome = await keystoneContext.prisma.welcomePage.findFirst();

  return {
    props: {
      sponsors,
      welcome,
    },
    revalidate: env.REVALIDATE_PAGE_TIME_MINUTES * 60,
  };
}

const SponsorItem = ({ sponsor }: { sponsor: Sponsor }) => {
  if (
    !sponsor ||
    !sponsor.logo_id ||
    !sponsor.logo_extension ||
    !sponsor.logo_width ||
    !sponsor.logo_height
  )
    return null;

  const SponsorImage = () => (
    <Image
      src={`${env.NEXT_PUBLIC_CMS_URL}/images/${sponsor.logo_id}.${sponsor.logo_extension}`}
      alt={sponsor.title}
      className="max-h-full w-auto"
      width={Math.round(50 * (sponsor.logo_width / sponsor.logo_height))}
      height={50}
    />
  );

  if (sponsor.url)
    return (
      <a href={sponsor.url} target="_blank">
        <SponsorImage />
      </a>
    );
  else return <SponsorImage />;
};

/**
 * The page that the user is guided to if they have not yet entered an address
 * This will be the first page the user sees upon entering the site
 */
const Welcome: NextPage<{
  sponsors: Sponsor[];
  welcome: WelcomePage;
  version: string;
}> = ({ sponsors, welcome, version }) => {
  return (
    <Layout
      noJsRedirect="/legacy"
      pageDescription="Displays the Emergency Department average wait times dashboard for hospitals within Vancouver, Richmond, and North Shore regions."
    >
      <section className="mb-10">
        <div className="mb-10 flex flex-shrink-0 items-center gap-4 text-xl transition-opacity">
          {/* eslint-disable @next/next/no-img-element */}
          <img
            className="block h-16 w-auto xs:h-20"
            src="/logo.svg"
            alt="Emergency Department Wait Times logo"
            width={50}
            height={50}
          />
          <h1 className="text-base xs:text-lg">
            <span className="font-bold">Emergency Department</span>
            <br />
            <span className="">Wait Times</span>
          </h1>
        </div>

        <LazyMotion features={lazyloadFeatures}>
          <m.div
            layout="position"
            layoutId="address-search"
            transition={{ type: "tween" }}
          >
            <AddressSearch autoFocus large />
          </m.div>
        </LazyMotion>
      </section>

      {welcome && welcome.document ? (
        <section className="mb-10">
          <article className="prose">
            {/* @ts-ignore */}
            <DocumentRenderer document={welcome.document} />
          </article>
        </section>
      ) : (
        false
      )}

      <section className="mb-10">
        <h2 className="mb-5 text-center text-gray">Proudly sponsored by:</h2>
        <ul className="flex flex-wrap justify-center gap-x-12 gap-y-8">
          {sponsors.map((sponsor) => (
            <li
              key={sponsor.id}
              className="flex h-10 items-center justify-center xs:h-12 sm:h-16 sm:min-w-[5rem] lg:h-20"
            >
              <SponsorItem sponsor={sponsor} />
            </li>
          ))}
        </ul>
      </section>
      <hr className="mt-10 text-gray-light" />
    </Layout>
  );
};

export default Welcome;
