import { FaAngleRight } from "react-icons/fa";

import clsx from "clsx";

const Resource = ({
  href,
  title,
  description,
}: {
  href: string;
  title: string;
  description: string;
}) => {
  return (
    <a className="inline-block w-full no-underline" href={href} target="_blank">
      <div className="flex cursor-pointer items-center justify-between gap-5">
        <h3 className=" m-0 my-2 text-base font-bold text-blue">{title}</h3>
        <FaAngleRight className="text-blue" />
      </div>

      <p className="m-0 font-normal">{description}</p>
    </a>
  );
};

export default Resource;
