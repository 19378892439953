import { IconBaseProps } from "react-icons";

// Import all icons made available to CMS Icon selector
import { FaHospitalSymbol, FaHospitalAlt } from "react-icons/fa";

// Add to object
const icons = {
  FaHospitalSymbol,
  FaHospitalAlt,
};

/**
 * Dynamic Icon component that renders icon based on string passed into name prop
 */
export const Icon = ({
  name,
  ...props
}: {
  /**
   * Pass in a valid icon name from the font awesome iconset
   */
  name: string;
} & IconBaseProps) => {
  if (Object.hasOwn(icons, name)) {
    const IconComponent = icons[name];
    return <IconComponent {...props}></IconComponent>;
  }
  return null;
};
