import Link from "next/link";

import clsx from "clsx";
import Resource from "./Resource";
import { ComponentProps } from "react";

const Resources = ({ items }: { items: ComponentProps<typeof Resource>[] }) => {
  return (
    <ol className="grid list-none divide-y divide-gray-light border-y border-gray-light p-0">
      {items.map((item) => (
        <li className="" key={item.title + item.href}>
          <Resource
            title={item.title}
            description={item.description}
            href={item.href}
          />
        </li>
      ))}
    </ol>
  );
};

export default Resources;
