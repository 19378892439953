import clsx from "clsx";
import { useState, type ReactNode } from "react";
import { useSearchParams } from "next/navigation";

import Accordion from "./Accordion";

type AccordionItem = {
  title: string;
  details: ReactNode;
};

// Custom Hook to manage the state for each item
function useItemOpenStates(items: AccordionItem[]) {
  const initialState = items.map(() => false);
  const [accordionOpenStates, setItemOpenStates] =
    useState<boolean[]>(initialState);

  return {
    accordionOpenStates,
    setAccordionOpen: (index: number, newValue: boolean) => {
      setItemOpenStates((prevState) => {
        const newState = [...prevState];
        newState[index] = newValue;
        return newState;
      });
    },
  };
}

const AccordionGroup = ({ items }: { items: AccordionItem[] }) => {
  const { accordionOpenStates, setAccordionOpen } = useItemOpenStates(items);

  function onAccordionChange(changedAccordionIndex: number, isOpen: boolean) {
    if (isOpen === true) {
      accordionOpenStates.forEach((x, index) => {
        // const [accordionOpen, setAccordionOpen] = x;

        if (changedAccordionIndex === index) {
          setAccordionOpen(index, isOpen);
        } else {
          setAccordionOpen(index, false);
        }
      });
    } else {
      setAccordionOpen(changedAccordionIndex, isOpen);
    }
  }

  return (
    <ol className="m-0 list-none p-0">
      {items.map((item, index) => (
        <li
          className="border-b border-gray-light"
          key={"accordionGroup" + index}
        >
          <Accordion
            title={item.title}
            open={accordionOpenStates[index]}
            onChange={(isOpen) => onAccordionChange(index, isOpen)}
          >
            {item.details}
          </Accordion>
        </li>
      ))}
    </ol>
  );
};

export default AccordionGroup;
